h2 {
    text-align: center;
}

h5 {
    text-align: center
}

.bg-suas {
    background-color: #1c2641;
}

img{
    image-rendering: crisp-edges;
    image-resolution: normal;
}

$progress-height: 3rem;
$progress-bar-bg: #a23036;

@import "../node_modules/bootstrap/scss/bootstrap";
